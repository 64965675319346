export default {
    'select_company': 'Select Company',
    'name': 'Name',
    'finished_date': 'Finished Date',
    'address': 'Address',
    'status': 'Status',
    'state': 'State',
    'description': 'Description',
    'create_project': 'Create Project',
    'update_project': 'Update Project',
    'title': 'Project',
    'location': 'Location',
    'company': 'Company',
    'created_by': 'Created By',
    'last_updated_by': 'Last Updated By',
    'thumbnail': 'Thumbnail'
}