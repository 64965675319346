import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';

// router setup
import router from './routes/router';

// store setup
import store from './store';

// multilang setup
import i18n from './i18n';

// plugin setup
Vue.use(DashboardPlugin);

Vue.use(require('vue-cookies'))

// mixin
import mixins from './mixins';
Vue.mixin(mixins);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
  i18n,
});
