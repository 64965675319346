export default {
    'title': 'Property',
    'select_project': 'Project',
    'name': 'Name',
    'color': 'Color',
    'description': 'Description',
    'address': 'Address',
    'overview': 'Overview',
    'size': 'Size',
    'thumbnail': 'Thumbnail',
    'feature_image': 'Feature Image',
    'type': 'Type',
    'max_price': 'Max Price',
    'min_price': 'Min Price',
    'discount_value': 'Discount Value',
    'discount_type': 'Discount Type',
    'state': 'State',
    'update_property': 'Update Property',
    'create_property': 'Create Property',
    'sizes': {
        'm': 'Meduim',
        's': 'Small'
    }
}