// js import
import swal from 'sweetalert2'

// style import
import 'sweetalert2/dist/sweetalert2.css'

export default {
    methods: {
        popupSuccess(text) {
            swal.fire({
                title: `Here's a message!`,
                text,
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-primary'
            });
        },
        popupError(text) {
            swal.fire({
                text,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-primary'
            });
        },
        popupConfirm(title, text, onConfirm) {
            swal.fire({
                title,
                text,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    onConfirm();
                }
            })
        },
        popupConfirmDelete(onConfirm) {
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                    onConfirm();
                }
              });
        },
        toastSuccess(text) {
            swal.fire({
                toast: true,
                position: 'top-right',
                title: 'Success',
                showConfirmButton: false,
                timer: 1500,
                icon: 'success',
                text
            });
        }
    }
}