export default [
    {
        path: '/orders/list',
        name: 'orders.index',
        component: () => import('@views/orders/Index.vue'),
    },
    {
        path: '/orders/reports/customerSettlements',
        name: 'orders.reports.customerSettlements.index',
        component: () => import('@views/reports/customer_settlements/Index.vue'),
    },
    {
        path: '/orders/reports/customerDeliveryFee',
        name: 'orders.reports.customerDeliveryFee.index',
        component: () => import('@views/reports/customer_delivery_fee/Index.vue'),
    },
    {
        path: '/orders/reports/driverReport',
        name: 'orders.reports.driverReport.index',
        component: () => import('@views/reports/driver_report/Index.vue'),
    },
    {
        path: '/all/orders/list',
        name: 'all.orders.index',
        component: () => import('@views/all/orders/Index.vue'),
    },
    {
        path: '/all/orders/reports/customerSettlements',
        name: 'all.orders.reports.customerSettlements.index',
        component: () => import('@views/all/reports/customer_settlements/Index.vue'),
    },
    {
        path: '/all/orders/reports/customerDeliveryFee',
        name: 'all.orders.reports.customerDeliveryFee.index',
        component: () => import('@views/all/reports/customer_delivery_fee/Index.vue'),
    },
    {
        path: '/all/orders/reports/driverReport',
        name: 'all.orders.reports.driverReport.index',
        component: () => import('@views/all/reports/driver_report/Index.vue'),
    },
];