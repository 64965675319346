import Vue from "vue";
import Constants from "@/constants";

export default {
    STORE_AUTHENTICATED_USER(state, payload) {
        state.authenticatedUser = payload;
        if (payload.token) {
            Vue.$cookies.set(Constants.ACCESS_TOKEN, payload.token);
        }
        Vue.$cookies.set(Constants.NAME, payload.name);
    },
    CLEAR_AUTHENTICATED_USER() {
        Vue.$cookies.remove(Constants.ACCESS_TOKEN);
    }
}