import language from "./language";
import general from './general';
import project from './project';
import property from './property';

export default {
    language,
    general,
    project,
    property,
    'invalid_login' : 'អ៊ីមែល ឬ​​ លេខសម្ងាត់មិនត្រឹមត្រូវ!'
}