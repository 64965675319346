import beforeEach from './beforeEach';
import beforeResolve from './beforeResolve';
import afterEach from './afterEach';

const guards = {
    beforeEach,
    beforeResolve,
    afterEach
}

export default guards;