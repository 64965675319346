import AuthRequest from '@requests/auth_request';
import router from '@/routes/router';

export default {
    login({ commit }, credentials) {
        AuthRequest.login(credentials)
            .then(({ data }) => {
                commit('STORE_AUTHENTICATED_USER', data);
                router.push({ name: 'dashboard' });
            });
    },
    async logout({ commit }, skipRequest = false) {
        if (!skipRequest) {
            await AuthRequest.logout();
        }

        commit('CLEAR_AUTHENTICATED_USER');
        router.push({ name: 'auth.login' });
    }
}