import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    namespaced: true,
    state: () => ({
        authenticatedUser: {
            id: null,
            name: null,
            token: null
        },
        permissions: [],
    }),
    mutations,
    actions,
    getters
}