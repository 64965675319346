export default class BaseResponse {

    constructor({ data, message, meta, status_code }, requestConfig) {
        this._data = data;
        this._meta = meta;
        this._message = message;
        this._statusCode = status_code;
        this._requestConfig = requestConfig;
    }

    getData() {
        return this._data;
    }

    getMeta() {
        return this._meta;
    }

    getMessage() {
        return this._message;
    }

    isFileDownload() {
        return this._requestConfig && this._requestConfig.responseType === 'blob';
    }

    statusOK() {
        return this._statusCode === 200;
    }

    statusUnauthorize() {
        return this._statusCode === 401;
    }

    statusNotFound() {
        return this._statusCode === 404;
    }

    statusForbidden() {
        return this._statusCode === 403;
    }

    statusUnprocessableEntity() {
        return this._statusCode === 422;
    }

}