export default [
    {
        path: '/destinations',
        name: 'destinations.index',
        component: () => import('@views/destinations/Index.vue'),
    },
    {
        path: '/destinations/create',
        name: 'destinations.create',
        component: () => import('@views/destinations/Entity.vue'),
    },
    {
        path: '/destinations/:id/edit',
        name: 'destinations.edit',
        component: () => import('@views/destinations/Entity.vue'),
    },
];