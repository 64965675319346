// axios
import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import BaseResponse from '@/responses/base_response';
import BaseErrorResponse from '@/responses/base_error_response';
import Constants from '@/constants';
import i18n from '@/i18n';

let baseURL = process.env.VUE_APP_API_BASE_URL;

const http = axios.create({
    baseURL,
    // define default headers here
    headers: {
        'Accept': 'application/json',
    }
});

// request interceptor
http.interceptors.request.use(
    configuration => {
        configuration.headers.common["Authorization"] = `Bearer ${ Vue.$cookies.get(Constants.ACCESS_TOKEN) }`;
        configuration.headers.common["X-Locale"] = i18n.locale;
        return configuration;
    },
    e => {
        return Promise.reject(e);
    }
);

// response interceptor
http.interceptors.response.use(
    httpResponse => {
        const instance = new Vue();
        let response = new BaseResponse(httpResponse.data, httpResponse.config);

        // check if request download file
        if (response.isFileDownload()) {
            if (httpResponse.config.preview) {
                window.open(URL.createObjectURL(httpResponse.data));
            } else {
                var fileDownload = require('js-file-download');
                fileDownload(httpResponse.data, httpResponse.headers['x-file-name']);
            }
            return Promise.resolve();
        }

        if (response.statusUnprocessableEntity()) {
            // do something
            instance.popupError(response.getMessage());
            // toastError(response.getMessage(), false);

            return Promise.reject(response.getData());
        } else if (response.statusUnauthorize()) {
            // do something
            store.dispatch('auth/logout', true);
            return Promise.reject(response.getData());
        } else if (response.statusForbidden()) {
            // do something

            return Promise.reject(response.getData());
        } else if (response.statusNotFound()) {
            // do something

            return Promise.reject(response.getData());
        }

        return Promise.resolve({
            data: response.getData(),
            meta: response.getMeta(),
            message: response.getMessage(),
            startTime: httpResponse.config.startTime
        });
    },
    (error) => {
        let errorResponse = new BaseErrorResponse(error.response);

        if (errorResponse.statusUnauthorize()) {
            // do something
            store.dispatch('auth/logout', true);
        }

        return Promise.reject(error);
    }
);

export default http;
