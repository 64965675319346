import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import guards from './guards';

import middlewares from './middlewares';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes,
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach(guards.beforeEach);
router.beforeResolve(guards.beforeResolve);
router.afterEach(guards.afterEach);


function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
      // Run the default Vue Router `next()` callback first.
      context.next(...parameters);
      // Then run the subsequent Middleware with a new
      // `nextMiddleware()` callback.
      const nextMiddleware = nextFactory(context, middleware, index + 1);
      subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  let middleware = to.meta.middleware;

  if (middleware == null) {
      middleware = [ middlewares.authMiddleware ];
  } else if (middleware.length === 0) {
      middleware = [
          () => next()
      ];
  }

  middleware = Array.isArray(middleware)
          ? middleware
          : [middleware];

  const context = {
      from,
      next,
      router,
      to,
  };
  const nextMiddleware = nextFactory(context, middleware, 1);

  return middleware[0]({ ...context, next: nextMiddleware });
});

export default router;
