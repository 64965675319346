import http from '@/axios';

export default class BaseRequest {

    static get METHOD_GET() {
        return 'GET';
    }

    static get METHOD_POST() {
        return 'POST';
    }

    static get METHOD_PUT() {
        return 'PUT';
    }

    static get METHOD_DELETE() {
        return 'DELETE';
    }

    static get METHOD_PATCH() {
        return 'PATCH';
	}

    constructor(prefix, data = {}) {
        this.prefix = prefix;
        this.data = data;
    }

    index(data) {
        return this.performRequest(BaseRequest.METHOD_GET, '', {...this.data, ...data});
    }

    show(id, data) {
        return this.performRequest(BaseRequest.METHOD_GET, id, {...this.data, ...data});
    }

    store(data) {
        return this.performRequest(BaseRequest.METHOD_POST, '', {...this.data, ...data});
    }

    update(id, data) {
        return this.performRequest(BaseRequest.METHOD_PUT, id, {...this.data, ...data});
    }

    destroy(id, data) {
        return this.performRequest(BaseRequest.METHOD_DELETE, id, {...this.data, ...data});
    }

    performDownload(url, data, requestOptions) {
        return this.performRequest(BaseRequest.METHOD_POST, url, data, {}, {
            responseType: 'blob',
            ...requestOptions
        });
    }

    performRequest(method, url, data = {}, headers = {}, requestOptions = {}) {
        let options = {
            method,
            url: this.prefix + (url ? "/" + url : ''),
            data,
            headers,
            ...requestOptions,
            startTime: performance.now()
        };

        options[method.toUpperCase() === BaseRequest.METHOD_GET ? 'params' : 'data'] = data;
        return http(options);
    }

}