export default class Constants {

    static get ACCESS_TOKEN() {
        return 'accessToken'
    }

    static get NAME() {
        return 'name'
    }

}