<template>
  <div class="wrapper">
    <notifications></notifications>
    <app-sidebar></app-sidebar>

    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <FadeTransition :duration="200" origin="center top" mode="out-in">
          <router-view></router-view>
        </FadeTransition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';
  import AuthRequest from '@requests/auth_request';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition,
      AppSidebar: () => import('./AppSidebar.vue')
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    mounted() {
      this.initScrollbar();
      AuthRequest.me().then(({ data }) => {
        this.$store.commit('auth/STORE_AUTHENTICATED_USER', data);
      });
    }
  };
</script>
<style lang="scss">
</style>
