export default [
    {
        path: '/customers',
        name: 'customers.index',
        component: () => import('@views/customers/Index.vue'),
    },
    {
        path: '/customers/create',
        name: 'customers.create',
        component: () => import('@views/customers/Entity.vue'),
    },
    {
        path: '/customers/:id/edit',
        name: 'customers.edit',
        component: () => import('@views/customers/Entity.vue'),
    },
];