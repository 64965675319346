export default [
    {
        path: '/drivers',
        name: 'drivers.index',
        component: () => import('@views/drivers/Index.vue'),
    },
    {
        path: '/drivers/create',
        name: 'drivers.create',
        component: () => import('@views/drivers/Entity.vue'),
    },
    {
        path: '/drivers/:id/edit',
        name: 'drivers.edit',
        component: () => import('@views/drivers/Entity.vue'),
    },
    {
        path: '/drivers/salary/daily',
        name: 'drivers.salary.daily.index',
        component: () => import('@views/drivers/salary/daily/Index.vue'),
    },
    {
        path: '/drivers/salary/weekly',
        name: 'drivers.salary.weekly.index',
        component: () => import('@views/drivers/salary/weekly/Index.vue'),
    }
];