import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './messages';

Vue.use(VueI18n);

const currentLang = process.env.VUE_APP_LOCALE || 'en'

const i18n = new VueI18n({
    locale: currentLang,
    fallbackLocale: currentLang,
    messages
});

export default i18n;