export default {
    'cancel': 'Cancel',
    'submit': 'Submit',
    'create': 'Add New',
    'please_select': 'Please Select',
    'location': 'Location',
    'reset_marker': 'Reset Marker',
    'goto_center': 'Go to center',
    'enter_here': 'Enter here',
    'choose_here': 'Choose here',
    'go_back': 'Go Back'
}