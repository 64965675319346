import DashboardLayout from '@/views/layout/DashboardLayout.vue';
import AuthLayout from '@views/auth/AuthLayout.vue';

import middlewares from './middlewares';

const FormComponents = () => import('@/views/forms/FormComponents.vue');
const FormElements = () => import('@/views/forms/FormElements.vue');
const Dashboard = () => import ('@views/dashboard/Index');

const Login = () => import('@/views/auth/Login.vue');
const NotFound = () => import('@views/errors/404');

import customer from './customer';
import driver from './driver';
import destination from './destination';
import order from './order';

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard
      },
      {
        path: 'form-components',
        name: 'form-components',
        component: FormComponents
      },
      {
        path: 'form-elements',
        name: 'form-elements',
        component: FormElements
      },
      ...customer,
      ...driver,
      ...destination,
      ...order
    ]
  },
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'auth.login',
        component: Login,
        meta: {
          middleware: []
        }
      },
      // { path: '*', component: NotFound }
    ]
  }
];

export default routes;
